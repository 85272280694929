.btn {
  --card-btn-padding-x: 1.5rem;
  --card-btn-padding-y: 0.5rem;
  --card-btn-font-family: ;
  --card-btn-font-size: 1rem;
  --card-btn-font-weight: 400;
  --card-btn-line-height: 1.5;
  --card-btn-color: #6d6d6d;
  --card-btn-bg: transparent;
  --card-position: relative;
  --card-btn-border-width: 1px;
  --card-btn-border-color: transparent;
  --card-btn-border-radius: 0.25rem;
  --card-btn-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  --card-btn-disabled-opacity: 0.65;
  --card-btn-focus-box-shadow: 0 0 0 0rem
    rgba(var(--card-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--card-btn-padding-y) var(--card-btn-padding-x);
  font-family: var(--card-btn-font-family);
  font-size: var(--card-btn-font-size);
  font-weight: var(--card-btn-font-weight);
  line-height: var(--card-btn-line-height);
  color: var(--card-btn-color);
  text-align: center;
  position: var(--card-position);
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: var(--card-btn-border-width) solid var(--card-btn-border-color);
  -webkit-border-radius: var(--card-btn-border-radius);
  border-radius: var(--card-btn-border-radius);
  background-color: var(--card-btn-bg);
  -webkit-box-shadow: var(--card-btn-box-shadow);
  box-shadow: var(--card-btn-box-shadow);
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.btn:hover {
  color: var(--card-btn-hover-color);
  background-color: var(--card-btn-hover-bg);
  border-color: var(--card-btn-hover-border-color);
}

.btn-check:focus + .btn,
.btn:focus {
  color: var(--card-btn-hover-color);
  background-color: var(--card-btn-hover-bg);
  border-color: var(--card-btn-hover-border-color);
  outline: 0;
  -webkit-box-shadow: var(--card-btn-box-shadow),
    var(--card-btn-focus-box-shadow);
  box-shadow: var(--card-btn-box-shadow), var(--card-btn-focus-box-shadow);
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
  color: var(--card-btn-active-color);
  background-color: var(--card-btn-active-bg);
  border-color: var(--card-btn-active-border-color);
  -webkit-box-shadow: var(--card-btn-active-shadow);
  box-shadow: var(--card-btn-active-shadow);
}

.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.show:focus {
  -webkit-box-shadow: var(--card-btn-active-shadow),
    var(--card-btn-focus-box-shadow);
  box-shadow: var(--card-btn-active-shadow), var(--card-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--card-btn-disabled-color);
  pointer-events: none;
  background-color: var(--card-btn-disabled-bg);
  border-color: var(--card-btn-disabled-border-color);
  opacity: var(--card-btn-disabled-opacity);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary {
  --card-btn-color: #fff;
  --card-btn-bg: #7016d0;
  --card-btn-border-color: #7016d0;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #5f13b1;
  --card-btn-hover-border-color: #5a12a6;
  --card-btn-focus-shadow-rgb: 133, 57, 215;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #5a12a6;
  --card-btn-active-border-color: #54119c;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: #7016d0;
  --card-btn-disabled-border-color: #7016d0;
}

.btn-secondary {
  --card-btn-color: #000;
  --card-btn-bg: #aca4bc;
  --card-btn-border-color: #aca4bc;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #b8b2c6;
  --card-btn-hover-border-color: #b4adc3;
  --card-btn-focus-shadow-rgb: 146, 139, 160;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #bdb6c9;
  --card-btn-active-border-color: #b4adc3;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: #aca4bc;
  --card-btn-disabled-border-color: #aca4bc;
}

.btn-success {
  --card-btn-color: #fff;
  --card-btn-bg: #1aa053;
  --card-btn-border-color: #1aa053;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #168847;
  --card-btn-hover-border-color: #158042;
  --card-btn-focus-shadow-rgb: 60, 174, 109;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #158042;
  --card-btn-active-border-color: #14783e;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: #1aa053;
  --card-btn-disabled-border-color: #1aa053;
}

.btn-info {
  --card-btn-color: #000;
  --card-btn-bg: #08b1ba;
  --card-btn-border-color: #08b1ba;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #2dbdc4;
  --card-btn-hover-border-color: #21b9c1;
  --card-btn-focus-shadow-rgb: 7, 150, 158;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #39c1c8;
  --card-btn-active-border-color: #21b9c1;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: #08b1ba;
  --card-btn-disabled-border-color: #08b1ba;
}

.btn-warning {
  --card-btn-color: #fff;
  --card-btn-bg: #f16a1b;
  --card-btn-border-color: #f16a1b;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #cd5a17;
  --card-btn-hover-border-color: #c15516;
  --card-btn-focus-shadow-rgb: 243, 128, 61;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #c15516;
  --card-btn-active-border-color: #b55014;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: #f16a1b;
  --card-btn-disabled-border-color: #f16a1b;
}

.btn-danger {
  --card-btn-color: #fff;
  --card-btn-bg: #c03221;
  --card-btn-border-color: #c03221;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #a32b1c;
  --card-btn-hover-border-color: #9a281a;
  --card-btn-focus-shadow-rgb: 201, 81, 66;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #9a281a;
  --card-btn-active-border-color: #902619;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: #c03221;
  --card-btn-disabled-border-color: #c03221;
}

.btn-light {
  --card-btn-color: #000;
  --card-btn-bg: #dee2e6;
  --card-btn-border-color: #dee2e6;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #bdc0c4;
  --card-btn-hover-border-color: #b2b5b8;
  --card-btn-focus-shadow-rgb: 189, 192, 196;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #b2b5b8;
  --card-btn-active-border-color: #a7aaad;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: #dee2e6;
  --card-btn-disabled-border-color: #dee2e6;
}

.btn-dark {
  --card-btn-color: #fff;
  --card-btn-bg: #161d2b;
  --card-btn-border-color: #161d2b;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #393f4b;
  --card-btn-hover-border-color: #2d3440;
  --card-btn-focus-shadow-rgb: 57, 63, 75;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #454a55;
  --card-btn-active-border-color: #2d3440;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: #161d2b;
  --card-btn-disabled-border-color: #161d2b;
}

.btn-tertiray {
  --card-btn-color: #000;
  --card-btn-bg: #3ff0b9;
  --card-btn-border-color: #3ff0b9;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #5cf2c4;
  --card-btn-hover-border-color: #52f2c0;
  --card-btn-focus-shadow-rgb: 54, 204, 157;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #65f3c7;
  --card-btn-active-border-color: #52f2c0;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: #3ff0b9;
  --card-btn-disabled-border-color: #3ff0b9;
}

.btn-gray {
  --card-btn-color: #fff;
  --card-btn-bg: #495057;
  --card-btn-border-color: #495057;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #3e444a;
  --card-btn-hover-border-color: #3a4046;
  --card-btn-focus-shadow-rgb: 100, 106, 112;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #3a4046;
  --card-btn-active-border-color: #373c41;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: #495057;
  --card-btn-disabled-border-color: #495057;
}

.btn-outline-primary {
  --card-btn-color: #7016d0;
  --card-btn-border-color: #7016d0;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #7016d0;
  --card-btn-hover-border-color: #7016d0;
  --card-btn-focus-shadow-rgb: 112, 22, 208;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #7016d0;
  --card-btn-active-border-color: #7016d0;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #7016d0;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #7016d0;
  --card-gradient: none;
}

.btn-outline-secondary {
  --card-btn-color: #aca4bc;
  --card-btn-border-color: #aca4bc;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #aca4bc;
  --card-btn-hover-border-color: #aca4bc;
  --card-btn-focus-shadow-rgb: 172, 164, 188;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #aca4bc;
  --card-btn-active-border-color: #aca4bc;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #aca4bc;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #aca4bc;
  --card-gradient: none;
}

.btn-outline-success {
  --card-btn-color: #1aa053;
  --card-btn-border-color: #1aa053;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #1aa053;
  --card-btn-hover-border-color: #1aa053;
  --card-btn-focus-shadow-rgb: 26, 160, 83;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #1aa053;
  --card-btn-active-border-color: #1aa053;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #1aa053;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #1aa053;
  --card-gradient: none;
}

.btn-outline-info {
  --card-btn-color: #08b1ba;
  --card-btn-border-color: #08b1ba;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #08b1ba;
  --card-btn-hover-border-color: #08b1ba;
  --card-btn-focus-shadow-rgb: 8, 177, 186;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #08b1ba;
  --card-btn-active-border-color: #08b1ba;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #08b1ba;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #08b1ba;
  --card-gradient: none;
}

.btn-outline-warning {
  --card-btn-color: #f16a1b;
  --card-btn-border-color: #f16a1b;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #f16a1b;
  --card-btn-hover-border-color: #f16a1b;
  --card-btn-focus-shadow-rgb: 241, 106, 27;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #f16a1b;
  --card-btn-active-border-color: #f16a1b;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #f16a1b;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #f16a1b;
  --card-gradient: none;
}

.btn-outline-danger {
  --card-btn-color: #c03221;
  --card-btn-border-color: #c03221;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #c03221;
  --card-btn-hover-border-color: #c03221;
  --card-btn-focus-shadow-rgb: 192, 50, 33;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #c03221;
  --card-btn-active-border-color: #c03221;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #c03221;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #c03221;
  --card-gradient: none;
}

.btn-outline-light {
  --card-btn-color: #dee2e6;
  --card-btn-border-color: #dee2e6;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #dee2e6;
  --card-btn-hover-border-color: #dee2e6;
  --card-btn-focus-shadow-rgb: 222, 226, 230;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #dee2e6;
  --card-btn-active-border-color: #dee2e6;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #dee2e6;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #dee2e6;
  --card-gradient: none;
}

.btn-outline-dark {
  --card-btn-color: #161d2b;
  --card-btn-border-color: #161d2b;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #161d2b;
  --card-btn-hover-border-color: #161d2b;
  --card-btn-focus-shadow-rgb: 22, 29, 43;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #161d2b;
  --card-btn-active-border-color: #161d2b;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #161d2b;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #161d2b;
  --card-gradient: none;
}

.btn-outline-tertiray {
  --card-btn-color: #3ff0b9;
  --card-btn-border-color: #3ff0b9;
  --card-btn-hover-color: #000;
  --card-btn-hover-bg: #3ff0b9;
  --card-btn-hover-border-color: #3ff0b9;
  --card-btn-focus-shadow-rgb: 63, 240, 185;
  --card-btn-active-color: #000;
  --card-btn-active-bg: #3ff0b9;
  --card-btn-active-border-color: #3ff0b9;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #3ff0b9;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #3ff0b9;
  --card-gradient: none;
}

.btn-outline-gray {
  --card-btn-color: #495057;
  --card-btn-border-color: #495057;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #495057;
  --card-btn-hover-border-color: #495057;
  --card-btn-focus-shadow-rgb: 73, 80, 87;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #495057;
  --card-btn-active-border-color: #495057;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #495057;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #495057;
  --card-gradient: none;
}

.btn-link {
  --card-btn-font-weight: 400;
  --card-btn-color: var(--bs-link-color);
  --card-btn-bg: transparent;
  --card-btn-border-color: transparent;
  --card-btn-hover-color: var(--bs-link-hover-color);
  --card-btn-hover-border-color: transparent;
  --card-btn-active-color: var(--bs-link-hover-color);
  --card-btn-active-border-color: transparent;
  --card-btn-disabled-color: #aca4bc;
  --card-btn-disabled-border-color: transparent;
  --card-btn-box-shadow: none;
  --card-btn-focus-shadow-rgb: 133, 57, 215;
  text-decoration: none;
}

.btn-link:focus {
  color: var(--card-btn-color);
}

.btn-link:hover {
  color: var(--card-btn-hover-color);
}

.btn-lg,
.btn-group-lg > .btn {
  --card-btn-padding-y: 1rem;
  --card-btn-padding-x: 2rem;
  --card-btn-font-size: 1.188rem;
  --card-btn-border-radius: 0.5rem;
}

.btn-sm,
.btn-group-sm > .btn {
  --card-btn-padding-y: 0.25rem;
  --card-btn-padding-x: 0.5rem;
  --card-btn-font-size: 0.813rem;
  --card-btn-border-radius: 0.125rem;
}

.btn-soft-primary {
  --card-btn-color: #430d7d;
  --card-btn-bg: rgba(112, 22, 208, 0.2);
  --card-btn-border-color: rgba(112, 22, 208, 0);
  --card-btn-hover-color: #54119c;
  --card-btn-hover-bg: rgba(112, 22, 208, 0.4);
  --card-btn-hover-border-color: rgba(112, 22, 208, 0);
  --card-btn-focus-shadow-rgb: 67, 13, 125;
  --card-btn-active-color: #6514bb;
  --card-btn-active-bg: rgba(112, 22, 208, 0.6);
  --card-btn-active-border-color: rgba(112, 22, 208, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: rgba(112, 22, 208, 0.2);
  --card-btn-disabled-border-color: rgba(112, 22, 208, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-secondary {
  --card-btn-color: #676271;
  --card-btn-bg: rgba(172, 164, 188, 0.2);
  --card-btn-border-color: rgba(172, 164, 188, 0);
  --card-btn-hover-color: #817b8d;
  --card-btn-hover-bg: rgba(172, 164, 188, 0.4);
  --card-btn-hover-border-color: rgba(172, 164, 188, 0);
  --card-btn-focus-shadow-rgb: 103, 98, 113;
  --card-btn-active-color: #9b94a9;
  --card-btn-active-bg: rgba(172, 164, 188, 0.6);
  --card-btn-active-border-color: rgba(172, 164, 188, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: rgba(172, 164, 188, 0.2);
  --card-btn-disabled-border-color: rgba(172, 164, 188, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-success {
  --card-btn-color: #106032;
  --card-btn-bg: rgba(26, 160, 83, 0.2);
  --card-btn-border-color: rgba(26, 160, 83, 0);
  --card-btn-hover-color: #14783e;
  --card-btn-hover-bg: rgba(26, 160, 83, 0.4);
  --card-btn-hover-border-color: rgba(26, 160, 83, 0);
  --card-btn-focus-shadow-rgb: 16, 96, 50;
  --card-btn-active-color: #17904b;
  --card-btn-active-bg: rgba(26, 160, 83, 0.6);
  --card-btn-active-border-color: rgba(26, 160, 83, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: rgba(26, 160, 83, 0.2);
  --card-btn-disabled-border-color: rgba(26, 160, 83, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-info {
  --card-btn-color: #056a70;
  --card-btn-bg: rgba(8, 177, 186, 0.2);
  --card-btn-border-color: rgba(8, 177, 186, 0);
  --card-btn-hover-color: #06858c;
  --card-btn-hover-bg: rgba(8, 177, 186, 0.4);
  --card-btn-hover-border-color: rgba(8, 177, 186, 0);
  --card-btn-focus-shadow-rgb: 5, 106, 112;
  --card-btn-active-color: #079fa7;
  --card-btn-active-bg: rgba(8, 177, 186, 0.6);
  --card-btn-active-border-color: rgba(8, 177, 186, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: rgba(8, 177, 186, 0.2);
  --card-btn-disabled-border-color: rgba(8, 177, 186, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-warning {
  --card-btn-color: #914010;
  --card-btn-bg: rgba(241, 106, 27, 0.2);
  --card-btn-border-color: rgba(241, 106, 27, 0);
  --card-btn-hover-color: #b55014;
  --card-btn-hover-bg: rgba(241, 106, 27, 0.4);
  --card-btn-hover-border-color: rgba(241, 106, 27, 0);
  --card-btn-focus-shadow-rgb: 145, 64, 16;
  --card-btn-active-color: #d95f18;
  --card-btn-active-bg: rgba(241, 106, 27, 0.6);
  --card-btn-active-border-color: rgba(241, 106, 27, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: rgba(241, 106, 27, 0.2);
  --card-btn-disabled-border-color: rgba(241, 106, 27, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-danger {
  --card-btn-color: #731e14;
  --card-btn-bg: rgba(192, 50, 33, 0.2);
  --card-btn-border-color: rgba(192, 50, 33, 0);
  --card-btn-hover-color: #902619;
  --card-btn-hover-bg: rgba(192, 50, 33, 0.4);
  --card-btn-hover-border-color: rgba(192, 50, 33, 0);
  --card-btn-focus-shadow-rgb: 115, 30, 20;
  --card-btn-active-color: #ad2d1e;
  --card-btn-active-bg: rgba(192, 50, 33, 0.6);
  --card-btn-active-border-color: rgba(192, 50, 33, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: rgba(192, 50, 33, 0.2);
  --card-btn-disabled-border-color: rgba(192, 50, 33, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-light {
  --card-btn-color: #85888a;
  --card-btn-bg: rgba(222, 226, 230, 0.2);
  --card-btn-border-color: rgba(222, 226, 230, 0);
  --card-btn-hover-color: #a7aaad;
  --card-btn-hover-bg: rgba(222, 226, 230, 0.4);
  --card-btn-hover-border-color: rgba(222, 226, 230, 0);
  --card-btn-focus-shadow-rgb: 133, 136, 138;
  --card-btn-active-color: #c8cbcf;
  --card-btn-active-bg: rgba(222, 226, 230, 0.6);
  --card-btn-active-border-color: rgba(222, 226, 230, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: rgba(222, 226, 230, 0.2);
  --card-btn-disabled-border-color: rgba(222, 226, 230, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-dark {
  --card-btn-color: #0d111a;
  --card-btn-bg: rgba(22, 29, 43, 0.2);
  --card-btn-border-color: rgba(22, 29, 43, 0);
  --card-btn-hover-color: #111620;
  --card-btn-hover-bg: rgba(22, 29, 43, 0.4);
  --card-btn-hover-border-color: rgba(22, 29, 43, 0);
  --card-btn-focus-shadow-rgb: 13, 17, 26;
  --card-btn-active-color: #141a27;
  --card-btn-active-bg: rgba(22, 29, 43, 0.6);
  --card-btn-active-border-color: rgba(22, 29, 43, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: rgba(22, 29, 43, 0.2);
  --card-btn-disabled-border-color: rgba(22, 29, 43, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-tertiray {
  --card-btn-color: #26906f;
  --card-btn-bg: rgba(63, 240, 185, 0.2);
  --card-btn-border-color: rgba(63, 240, 185, 0);
  --card-btn-hover-color: #2fb48b;
  --card-btn-hover-bg: rgba(63, 240, 185, 0.4);
  --card-btn-hover-border-color: rgba(63, 240, 185, 0);
  --card-btn-focus-shadow-rgb: 38, 144, 111;
  --card-btn-active-color: #39d8a7;
  --card-btn-active-bg: rgba(63, 240, 185, 0.6);
  --card-btn-active-border-color: rgba(63, 240, 185, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #000;
  --card-btn-disabled-bg: rgba(63, 240, 185, 0.2);
  --card-btn-disabled-border-color: rgba(63, 240, 185, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-soft-gray {
  --card-btn-color: #2c3034;
  --card-btn-bg: rgba(73, 80, 87, 0.2);
  --card-btn-border-color: rgba(73, 80, 87, 0);
  --card-btn-hover-color: #373c41;
  --card-btn-hover-bg: rgba(73, 80, 87, 0.4);
  --card-btn-hover-border-color: rgba(73, 80, 87, 0);
  --card-btn-focus-shadow-rgb: 44, 48, 52;
  --card-btn-active-color: #42484e;
  --card-btn-active-bg: rgba(73, 80, 87, 0.6);
  --card-btn-active-border-color: rgba(73, 80, 87, 0);
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #fff;
  --card-btn-disabled-bg: rgba(73, 80, 87, 0.2);
  --card-btn-disabled-border-color: rgba(73, 80, 87, 0);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-icon {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  text-align: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-icon.btn .btn-inner {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.btn-icon.btn .btn-inner svg {
  width: 1.5rem;
  height: 1.5rem;
}

.btn-icon.btn-sm,
.btn-group-sm > .btn-icon.btn {
  height: 1.875rem;
  width: 1.875rem;
  padding: 0;
  text-align: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-icon.btn-sm .btn-inner svg,
.btn-group-sm > .btn-icon.btn .btn-inner svg {
  width: 1rem;
  height: 1rem;
}

.btn-icon.btn-lg,
.btn-group-lg > .btn-icon.btn {
  height: 3rem;
  width: 3rem;
  padding: 0;
  text-align: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-icon.btn-lg .btn-inner svg,
.btn-group-lg > .btn-icon.btn .btn-inner svg {
  width: 2.5rem;
  height: 2.5rem;
}

.btn-setting {
  padding: 0.5rem;
  z-index: 1000;
  top: 50%;
}

.customizer-btn {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.btn-close {
  -webkit-border-radius: 0.125rem;
  border-radius: 0.125rem;
}

.btn.btn-fixed-end {
  position: fixed;
  right: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn.btn-fixed-start {
  position: fixed;
  left: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn.btn-fixed-top {
  position: fixed;
  left: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}

.btn.btn-fixed-bottom {
  position: fixed;
  left: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn.btn-download {
  position: fixed;
  top: 30%;
  right: -3px;
  -webkit-transform: rotate(-90deg) translate(50%, -100%);
  -ms-transform: rotate(-90deg) translate(50%, -100%);
  transform: rotate(-90deg) translate(50%, -100%);
  z-index: 10;
  -webkit-transform-origin: 100% 0% 0;
  -ms-transform-origin: 100% 0% 0;
  transform-origin: 100% 0% 0;
}

.btn.btn-border {
  --card-btn-color: #7016d0;
  --card-btn-border-color: #7016d0;
  --card-btn-hover-color: #fff;
  --card-btn-hover-bg: #7016d0;
  --card-btn-hover-border-color: #7016d0;
  --card-btn-focus-shadow-rgb: 112, 22, 208;
  --card-btn-active-color: #fff;
  --card-btn-active-bg: #7016d0;
  --card-btn-active-border-color: #7016d0;
  --card-btn-active-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  --card-btn-disabled-color: #7016d0;
  --card-btn-disabled-bg: transparent;
  --card-btn-disabled-border-color: #7016d0;
  --card-gradient: none;
  color: #aca4bc;
  border: 2px solid #eee;
  padding: 0.5rem 1rem;
}

.btn.btn-border:hover {
  background: rgba(0, 0, 0, 0);
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}

.btn-check:checked + .btn.btn-border,
.btn-check:active + .btn.btn-border,
.btn.btn-border:active,
.btn.btn-border.active {
  background: var(--bs-primary);
  color: var(--bs-white);
  border-color: var(--bs-primary);
}

.btn-check:checked + .btn.btn-border.bg-transparent,
.btn-check:active + .btn.btn-border.bg-transparent,
.btn.btn-border:active.bg-transparent,
.btn.btn-border.active.bg-transparent {
  color: var(--bs-primary);
}
