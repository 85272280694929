:root {
    --c-black: #000;
    --c-white: #FFF;

    --c-backgroundColor: #011d2c;

    --c-darkTintBlue: #0b3c57;
    --c-skyBlue: #96cbe8;
    --c-riverBlue: #268ec6;
    --c-miliBlue: #085680;
    --c-darkBlue: #052b40;
    --c-grenishBlue: #0d3f5a;
    --c-contrastBlack: #001724;
    --c-contrastBlue: #0a2d40;
    --c-borderColor: #7dbbbe;
 
  
    --fade: 150ms;

    --c-formation-1: #fda403;
    --c-formation-2: #e8751a;
    --c-formation-3: #c51350;
    --c-formation-4: #8a1253;
    --c-formation-5: #27296d;
    --c-formation-6: #5e63b6;
    --c-formation-7: #a393eb;
    --c-formation-8: #f5c7f7;
    --c-formation-9: #f83e4b;
    --c-formation-10: #f7e185;
    --c-formation-11: #cb9bba;
    --c-formation-12: #9d53c3;
  }
  